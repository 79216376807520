import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { ORANGE, DARKORANGE } from "../components/constants"

const StyledNotFound = styled.div`
    height: 100vh;
    width: 100%;
    display: inline-block;
`

const StyledError = styled.p`
    text-align: center;
    font-size: 200px;
    margin: 100px 200px 0;
`

const StyledParagraph = styled.p`
    font-size: 50px;
    text-align: center;
`

const StyledDiv = styled.div`
    border-radius: 5px;
    padding: 10px 20px;
    width: 20%;
    border: 0px;
    background-color: ${ORANGE};
    font-size: 22px;
    margin: 100px auto;

    :hover {
        background-color: ${DARKORANGE};
    }
`


const NotFound = () => {
    return (
        <StyledNotFound>
            <StyledError>
                404
            </StyledError>
            <StyledParagraph>
                Oops... Something went wrong!
            </StyledParagraph>
            <StyledDiv>
                <Link style={{display: "flex",  paddingLeft: "15px",  textDecoration: "none" }}  to="/../">
                    <p style={{margin: "auto", color: "#FFF", textAlign: "center"}}> Tag Tracker </p>
                </Link>
            </StyledDiv>
        </StyledNotFound>
    )
}

export default NotFound